<template>
  <div id="content">
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <!--  -->
    <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        layout="horizontal"
        :label-col="{span:6}"
        :wrapper-col="{span:14}"
      >
        <br/>
          <a-form-model-item label="提现最小金额" prop="withdrawalMinLimit">
            <a-input-number v-model="form.withdrawalMinLimit" style="width:200px;" />
          </a-form-model-item>
        <br/>
        <br/>
        <a-form-model-item label=" " :colon="false">
          <a-button type="primary" @click="onSubmit">
            保存
          </a-button>
        </a-form-model-item>
    </a-form-model>
    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
export default {
  components: {
    Breadcrumb
  },
  data () {
    const validInteger = (rule, value, callback) => {
      if (value) {
        if (!Number(value)) {
          return callback(new Error('请输入数值'))
        } else {
          var pattern = /^\d+.?\d{0,4}$/
          if (!pattern.test(value)) {
            return callback(new Error('提现最小金额需大于等于零且最多保留四位小数'))
          }
        }
      }
      callback()
    }
    return {
      visible: false,
      breadList: [
        {
          name: '提现设置',
          url: ''
        }
      ],
      form: {
        withdrawalMinLimit: null
      },
      rules: {
        withdrawalMinLimit: [
          { required: true, message: '请输入提现最小金额', trigger: 'blur' },
          { validator: validInteger, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getWithdrawalConfig()
  },
  computed: {
  },
  methods: {
    async getWithdrawalConfig () {
      const res = await this.$store.dispatch('http', {
        api: 'getWithdrawalConfig'
      })
      if (res) {
        this.form = {
          withdrawalMinLimit: res.withdrawalMinLimit
        }
      }
    },
    onSubmit () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          await this.$store.dispatch('http', {
            api: 'savetWithdrawalConfig',
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: '保存成功~' })
          this.getWithdrawalConfig()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .form__ct {
    padding: 10px 0;
  }
  .form__items {
    padding-top: 30px;
  }
}
</style>
